import app from "../../main";
import router from "../../router";
import {resetRouter} from "../../router/hook";

const login = {

    namespaced: true,

    state: {

        //重置密码弹窗开关
        resetPasswordDialogVisible: false,

        //修改密码弹窗
        passwordDialogVisible: false,

        //当前登录用户信息
        userMsg: {},

        //菜单权限列表
        menuData: {

            homeMenuList: [],

            settingMenuList: [],

            id: 0

        },

        //个人信息页面数据
        userInfo: {}

    },

    getters: {

        getHomeMenuRedirect(state) {
            let list =  app.$config.formatMenu(state.menuData.homeMenuList);
            console.log(list)
            return list[0] ? list[0].path ? list[0].path : "" : "";
        },

        getSettingMenuRedirect(state) {
            return state.menuData.settingMenuList[0] ? state.menuData.settingMenuList[0].uri : "";
        }

    },

    mutations: {

        SET_RESET_PASSWORD_DIALOG_VISIBLE: (state, resetPasswordDialogVisible) => {
            state.resetPasswordDialogVisible = resetPasswordDialogVisible;
        },

        SET_PASSWORD_DIALOG_VISIBLE: (state, passwordDialogVisible) => {
            state.passwordDialogVisible = passwordDialogVisible;
        },

        SET_USER_MSG: (state, userMsg) => {
            state.userMsg = app.$config.deepCopy(userMsg);
        },

        SET_MENU_DATA: (state, menuData) => {
            console.log("SET_MENU_DATA", menuData)
            state.menuData = {}
            state.menuData = app.$config.deepCopy(menuData);
        },

        SET_USERINFO: (state, userInfo) => {
            state.userInfo = {}
            state.userInfo = app.$config.deepCopy(userInfo);
        }

    },

    actions: {

        closeResetPasswordDialog({commit}) {
            commit('SET_RESET_PASSWORD_DIALOG_VISIBLE', false);
        },

        closePasswordDialog({commit}) {
            commit('SET_PASSWORD_DIALOG_VISIBLE', false);
        },

        /**
         * 登录
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async login({commit}, data) {
            return await app.$api.user.login(data)
        },

        /**
         * 注册
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async register({commit}, data) {
            return await app.$api.user.register(data)
        },

        /**
         * 获取用户信息
         * @param {*} param0
         */
        async getlogininfo({commit}) {
            let result = app.$api.user.getlogininfo();
            result.then(res => {
                commit("SET_USER_MSG", res.result);
            }, err => {
                console.log(err);
                app.$dialog.showMessage(err.resultMsg, app.$config.TOAST_ERROR);
            })
        },

        /**
         * 退出登录
         * @param {*} param0
         */
        async loginout({commit}) {
            let result = app.$api.user.loginout();
            result.then(res => {
                app.$dialog.showMessage("退出成功", app.$config.TOAST_SUCCESS);
                app.$token.removeToken();
                if (app.$route.path === "/") {
                    app.$router.go(0)
                } else {
                    app.$router.push({path: "/login"});
                }
                resetRouter();
            }, err => {
                app.$dialog.showMessage(err.resultMsg, app.$config.TOAST_ERROR);
            })
        },

        /**
         * 获取用户权限树
         * @param {*} param0
         * @returns
         */
        async getUserAuthorityTree({state, commit}) {
            console.log(router.getRoutes().length)
            if (router.getRoutes().length > 18) {
                state.menuData.id = Math.random() * 10;
                commit("SET_MENU_DATA", state.menuData)
                return;
            }
            await app.$api.auth.getUserAuthorityTree().then(res => {
                //重置路由
                resetRouter();
                if (!res.result) return
                console.log(router.getRoutes());
                let msg = app.$config.deepCopy(res);
                let homeMenuList = [];
                let settingMenuList = [];
                let menuList = {};

                res.result.forEach((item, index) => {
                    if (item.code === "200") {
                        menuList.homeMenuList = res.result[index].children;
                        homeMenuList = app.$config.formatMenu(res.result[index].children);
                    } else if (item.code === "201"){
                        menuList.settingMenuList = res.result[index].children;
                        settingMenuList = app.$config.formatMenu(res.result[index].children);
                    }
                });
                if (!menuList.homeMenuList) {
                    menuList.homeMenuList = [];
                }
                if (!menuList.settingMenuList) {
                    menuList.settingMenuList = [];
                }
                // if (res.result[0].uri == "/index") {
                //     menuList.homeMenuList = [res.result[0]];
                //     homeMenuList = app.$config.formatMenu([res.result[0]]);
                //     msg.result.splice(0,1)
                // }else{
                //     menuList.homeMenuList = [];
                // }
                // menuList.settingMenuList = msg.result;

                menuList.id = Math.random() * 10;
                // settingMenuList = app.$config.formatMenu(msg.result);
                // if (homeMenuList.length > 0) {
                //
                //     router.addRoute('main', homeMenuList[0]);
                // }
                homeMenuList.forEach(item => {
                    router.addRoute('main', item);
                })
                settingMenuList.forEach(item => {
                    router.addRoute('setting', item);
                })
                console.log(settingMenuList);
                console.log(router.getRoutes());
                commit("SET_MENU_DATA", menuList)

            }, err => {
                commit("SET_MENU_DATA", {
                    homeMenuList: [],
                    settingMenuList: [],
                    id: Math.random() * 10
                });
                app.$dialog.showMessage(err.resultMsg, app.$config.TOAST_ERROR);
            });
        },

        /**
         * 获取当前登录的管理员用户信息，IOT平台和IOT后台适用
         * @param {*} param0
         * @returns
         */
        async getAdminuserInfo({commit}) {
            app.$api.user.getAdminuserInfo().then(res => {
                commit("SET_USERINFO", res.result)
            }, err => {
                commit("SET_USERINFO", {})
                app.$dialog.showMessage(err.resultMsg, app.$config.TOAST_ERROR);
            });
        },

        /**
         * 修改用户信息
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async modifyUserInfo({commit}, data) {
            return await app.$api.user.modifyUserInfo(data);
        },

        /**
         * 发送手机验证码
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async sendSms({commit}, data) {
            return await app.$api.user.sendSms(data);
        },

        /**
         * 修改密码
         * @param {*} param0
         * @param {*} data
         */
        async modifyPassword({commit}, data) {
            return await app.$api.user.modifyPassword(data);
        },

        /**
         * 修改手机（第二步）
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async modifyPhone({commit}, data) {
            return await app.$api.user.modifyPhone(data);
        },

        /**
         * 重置密码
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async restPassword({commit}, data) {
            return await app.$api.user.restPassword(data);
        },

        /**
         * 获取当前用户手机验证码
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async sendUserSms({commit}, data) {
            return await app.$api.user.sendUserSms(data);
        },

        /**
         * 修改手机（第一步）
         * @param {*} param0
         * @param {*} data
         * @returns
         */
        async checkUserPhone({commit}, data) {
            return await app.$api.user.checkUserPhone(data);
        },

        /**
         * 判断用户是否已经被注册
         * @param commit
         * @param data
         * @returns {Promise<*>}
         */
        async isAllowRegistration({commit}, data) {
            return await app.$api.user.isAllowRegistration(data);
        },

        /**
         * 通过手机号验证码修改密码
         * @param commit
         * @param data
         * @returns {Promise<void>}
         */
        async modifyPasswordByPhone({commit}, data) {
            return await app.$api.user.modifyPasswordByPhone(data);
        }

    }

}

export default login;
