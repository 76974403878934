import app from "../main.js";

const ROUTER_MAPPER = {
    base: '/',
    mode: 'hash',
    routes: [
        {
            path: '/',
            name: 'index',
            component: () => import('@/views/index'),
            meta: {
                title: '朗国iot平台',
            },
        },
        {
            path: '/download',
            name: 'download',
            component: () => import('@/views/download'),
            meta: {
                title: 'APP下载',
            },
        },
        {
            path: '/main',
            name: 'main',
            component: () => import('@/views/main'),
            redirect: '/index',
            meta: {
                title: '朗国物联网平台',
            },
            children: [
                // {
                //     path: '/index',
                //     name: 'index',
                //     component: () => import('@/views/main/components/adminMain'),
                //     meta: {
                //         title: '产品开发',
                //     },
                //     children: []
                // },
                // {
                //     path: '/debug',
                //     name: 'debug',
                //     component: () => import('@/views/debug/debug'),
                //     meta: {
                //         title: '调试',
                //     },
                //     children: []
                // },
                {
                    path: '/addProduct',
                    name: 'addProduct',
                    component: () => import('@/views/product/addProduct'),
                    meta: {
                        title: '新建产品',
                        parentPath: '/index'
                    },
                    children: []
                },
                {
                    path: '/deviceManagement',
                    name: 'deviceManagement',
                    component: () => import('@/views/product/deviceManagement'),
                    meta: {
                        title: '设备管理',
                        parentPath: '/index'
                    },
                    children: []
                },
                {
                    path: '/product',
                    name: 'product',
                    component: () => import('@/views/product'),
                    redirect: '/product/basic',
                    meta: {
                        title: '朗国物联网平台',
                    },
                    children: [
                        {
                            path: '/product/basic',
                            name: 'basic',
                            component: () => import('@/views/product/views/basic'),
                            meta: {
                                title: '编辑产品',
                                parentPath: '/index'
                            }
                        }
                    ]
                },
                {
                    path: '/deviceDebug',
                    name: 'deviceDebug',
                    component: () => import('@/views/debug/newDeviceDebug'),
                    meta: {
                        title: '设备调试',
                        parentPath: '/debug'
                    },
                    children: []

                },
                {
                    path: '/applicationDebug',
                    name: 'applicationDebug',
                    component: () => import('@/views/debug/newApplicationDebug'),
                    meta: {
                        title: '应用调试',
                        parentPath: '/debug'
                    },
                    children: []
                },
                // {
                //     path: '/empower',
                //     name: 'empower',
                //     component: () => import('@/views/empower'),
                //     meta: {
                //         title: '授权服务',
                //     },
                //     children: []
                // },
                {
                    path: '/empowerMsg',
                    name: 'empowerMsg',
                    component: () => import('@/views/empower/empowerMsg'),
                    meta: {
                        title: '授权服务详情',
                        parentPath: '/empower'
                    },
                    children: []
                },
                // {
                //     path: '/afterSales',
                //     name: 'afterSales',
                //     component: () => import('@/views/maintain/afterSales'),
                //     meta: {
                //         title: '售后运维',
                //     },
                //     children: []
                // },
                // {
                //     path: '/deviceLog',
                //     name: 'deviceLog',
                //     component: () => import('@/views/maintain/deviceLog'),
                //     meta: {
                //         title: '设备日志',
                //     },
                //     children: []
                // },
                // {
                //     path: '/ota',
                //     name: 'ota',
                //     component: () => import('@/views/maintain/ota'),
                //     meta: {
                //         title: 'OTA升级',
                //     },
                //     children: []
                // },
                {
                    path: '/firmwareTest',
                    name: 'firmwareTest',
                    component: () => import('@/views/maintain/firmwareTest'),
                    meta: {
                        title: '测试',
                    },
                    children: []
                },
                {
                    path: '/firmwareMsg',
                    name: 'firmwareMsg',
                    component: () => import('@/views/maintain/firmwareMsg'),
                    meta: {
                        title: '详情',
                    },
                    children: []
                },

            ]
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/login'),
            meta: {
                title: '朗国iot平台登录',
            },
        },
        {
            path: '/protocol',
            name: 'protocol',
            component: () => import('@/views/protocol'),
            meta: {
                title: '朗国iot平台',
            },
        },
        {
            path: '*',
            name: 'transition',
            component: () => import('@/views/main/transition'),
            meta: {
                title: '朗国iot平台',
            },
        },
        {
            path: '/setting',
            name: 'setting',
            component: () => import('@/views/setting'),

            //获取vuex中存储的第一个首页用户菜单为重定向路由
            // redirect: to => app.$store.getters['login/getSettingMenuRedirect'],
            meta: {
                title: '朗国物联网平台',
            },
            children: [
                {
                    path: '/authentication',
                    name: 'authentication',
                    component: () => import('@/views/authentication'),
                    meta: {
                        title: '企业认证',
                        parentPath: '/setting/enterpriseInfo'
                    },
                    children: []
                }
            ]
        },
        {
            path: '/notice',
            name: 'notice',
            component: () => import('@/views/notice/notice'),
            meta: {
                title: '公告中心',
            },
            children: []
        }
    ],
}

export default ROUTER_MAPPER;
